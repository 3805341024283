export default {
	links: {
		catalogo: 'https://conteudo.colibrimoveis.com.br/colecao-2021',
	},
	dev: {
		SITE_URL: "http://0.0.0.0:3009",
		SERVER_URL: "http://0.0.0.0:3009/api/",
		BLOG_URL: "/blog",
		CLIENT_URL: "https://colibrimoveis.simplificamais.com.br/",
		STORAGE_URL: "https://www.colibrimoveis.com.br/files/",
		PROD: false
	},
	production: {
		SITE_URL: "https://www.colibrimoveis.com.br",
		SERVER_URL: "https://www.colibrimoveis.com.br/api/",
		BLOG_URL: "/blog",
		CLIENT_URL: "https://colibrimoveis.simplificamais.com.br/",
		STORAGE_URL: "https://www.colibrimoveis.com.br/files/",
		PROD: true
	}
}
