export const NavbarDiretive = {
  options: function () {
    return {
      restrict: 'E',
      template: require("./navbar.diretive.html"),
      scope: {},
      controller: ["$state", "$scope", "$element", "HttpService", "$translate", "$window", "$cookies", NavbarController],
      controllerAs: "vm",
      bindToController: true
    }
  },
  element: "navbarDiretive"
}

function NavbarController($state, $scope, $element, HttpService, $translate, $window, $cookies) {
  var vm = this

  vm.isMobile = false;
  vm.menuOpen = false;
  vm.search;

  if (window.innerWidth < 991){
    vm.isMobile = true;
  }
  
  
  $scope.currentLanguage = window.localStorage.getItem("NG_TRANSLATE_LANG_KEY") || "pt";

	$scope.changeLanguage = function (langKey) {
		$translate.use(langKey);
		$window.location.reload();
  };

  $scope.buscar = function(){
    setTimeout(function(){
      if (vm.search){
        window.location.href = "produtos/all/all/" + vm.search;
  
      }else{
        window.location.href = "produtos";
      }
    }, 300)
  }

  $scope.open = function(){
    $('#menu').toggleClass('menu-open')
    vm.menuOpen = !vm.menuOpen;
  }
  $scope.close = function(){
    $('#menu').removeClass('menu-open')
    vm.menuOpen = false;
  }

  $scope.acceptCookies = $cookies.get('acceptCookies');

  $scope.aceitarCookies = function() {
    $cookies.put('acceptCookies', 'true')
    $scope.acceptCookies = 'true';
  }

  HttpService.get("/ambientes/get-nav-info/", {}).then(function (resp) {
    var ambientes = resp.data.filter(item => item.categoriaproduto && item.categoriaproduto.length > 0);

    var filteredCategories = [];

    ambientes.forEach(element => {
      // debugger
      element.categoriaproduto.forEach(category => {
        if ($scope.currentLanguage == "pt"){
          category.nome = category.nome_pt
        } else if ($scope.currentLanguage == "en"){
          category.nome = category.nome_us
        } else if ($scope.currentLanguage == "es"){
          category.nome = category.nome_es
        }

        category.main_slug = element.slug

        filteredCategories.push(category)
      });
    });

    vm.filters = filteredCategories
	});
  // $scope.$watch(function () { return $state.$current.name }, function (newVal) {
  //   if (newVal === '/') $(window).scroll(function () {
  //     if ($(this).scrollTop() > 200)
  //       $element.addClass('invert')
  //     else
  //       $element.removeClass('invert')
  //   }) 
  //   else if(newVal !== '/'  && newVal !== ''){
  //     $element.addClass('invert')
  //     $("main").css("margin-top", "90px")
  //   }
  // })
}